var Emico = Emico || {};

Emico.CheckRadio = class {
    constructor(options) {
        this.addSpan = this.addSpan.bind(this);

        this.options = jQuery.extend({}, {
            checkRadioSelector: 'input[type=checkbox], input[type=radio]',
            labelSelector: 'label',
        }, options);

        this.addSpan();
    }

    addSpan() {
        jQuery(this.options.checkRadioSelector).parent().find(this.options.labelSelector).each((i, element) => {
            if (jQuery(element).find('.holder').length === 0) {
                jQuery(element).append('<span class="holder"></span>');
            }
        })
    }
};

jQuery(function () {
    new Emico.CheckRadio();
});